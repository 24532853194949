/**
 * Import Bootstrap components as needed within components.
 * Below is a full list of available modules within Bootstrap.
 */

/* exported Button, Dropdown, Offcanvas, Popover */
import { Button, Dropdown, Offcanvas, Popover } from 'bootstrap';

/* Importing Handlebar*/
import Handlebars from 'handlebars/dist/handlebars.min.js';
import Prism from 'prismjs';
import 'prismjs/plugins/autoloader/prism-autoloader';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import 'prismjs/plugins/highlight-keywords/prism-highlight-keywords';
import 'prismjs/plugins/command-line/prism-command-line';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace';
import 'prismjs/plugins/toolbar/prism-toolbar';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard';


window.Handlebars = Handlebars;

Prism.plugins.autoloader.languages_path = '/etc.clientlibs/common-ui/clientlibs/clientlib-site/resources/prism/';

export { Button, Dropdown, Offcanvas, Popover };
